import React from 'react';
import { Router } from 'react-router-dom';
import { rdxConnect, Appbar, Notification, Sidebar, navService, Companies } from 'libs/react-mpk';
import { utilsService } from 'services';
import { Button } from 'react-md';
import t from 'counterpart';
import routes from './routes';

class Main extends React.Component{
  componentDidMount(){
    utilsService.setReqOptionsCompanyId(null);
    utilsService.setReqOptionsCompanyName(null);
    utilsService.setReqOptionsSubscriptionId(null);
  }

  // componentDidMount(){
  //   let { auth, router, global} = this.props;

  //   utilsService.setReqOptionsCompanyId(null);
  //   utilsService.setReqOptionsSubscriptionId(null);

  //   if(global.asInternalService){
  //     navService.redirectTo('/internal/ssp')
  //   }else{
  //     if(auth.user.isSupport ) {
  //       if(router.location.pathname === router.match.path) navService.redirectTo(`/main/companies`);
  //     }else if(router.location.pathname !== router.match.path) navService.redirectTo(`/main`);
  //   }
    
  //   this.props.authActions.setProperties({
  //     company: null,
  //     meInCompany: null,
  //     subscription: null
  //   });
  // }

  redirectToCompany = (company, subscription) => {
    navService.redirectTo(`/company/${company.id}/${subscription.id}/registration/chart`)
  };

  render(){
    let { global, auth, router } = this.props;

    const Support = (
      <div className="flex mpk-layout">
        <Sidebar
          menus={[]}
          getValueByLocaleCode={true}
          collapsible={false}
        />
        <div className="flex mpk-position relative">
          <Router history={router.history}>
            {routes()}
          </Router>
        </div>
      </div>
    );

    return(
      <div className="mpk-layout column fill">
        <Appbar
          notification={<Notification newFeeds={0}/>}
          subname="BKWP Monitoring"
          version={global.appInfo.version}
          showVersion fixedVersion={false}
          profileMenu={[
            {
              label:"Profile",
              materialIcon:'perm_identity',
              onClick:() => {
                window.open(global.appConsole.userAccount.url, 'blank')
              }
            }
          ]}
          // appsShortcut={[]}
          consoleShortcut={Object.keys(this.props.global.appConsole).map(key => {
            return this.props.global.appConsole[key];
          })}
          translate={true}
        />
        { auth.user.isSupport ? (Support) : (
          <Companies 
            className="mpk-animation slide-in"
            onSelectCompany={this.redirectToCompany}
            ignoreSelectSubscription={false}
            footer={
              <div className="mpk-margin-N margin-top margin-bottom">
                <Button 
                  raised secondary
                  onClick={() => {
                    window.open(`${this.props.global.appConsole.userAccount.url}/main/access/company/add`)
                  }}
                >
                  {t.translate('sentence.addNewCompany')}
                </Button>
              </div>
            }
          />
        )}
      </div>
    )
  }
}

export default rdxConnect(Main);