import { httpService } from 'libs/react-mpk';
import { hosts } from 'services';

const companyService = {
  getById: (id) => {
    return httpService.get({
      url: `${hosts.getAll().iam.host}${hosts.baseUrl}/company/${id}`,
      config: {
        headers: {
          "X-COMPANY-ID": id,
        },
      },
    });
  },
  me: (id, subscriptionId, as = "user") => {
    return httpService.get({
      url: `/api/iams/company/${id}/${subscriptionId}/me/${as}`,
    });
  },
};

export default companyService;
