import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Institution, Registration, Validation, FileReport} from '../../modules';

export default () => (
  <Switch>
    <Route path="/internal/registration/:tab" render={(props) => (
      <Registration router={props}/>
    )}/>
    <Route path="/internal/validation/:tab" render={(props) => (
      <Validation router={props}/>
    )}/>
    <Route path="/internal/institution" render={(props) => (
      <Institution.List router={props}/>
    )}/>
    <Route path="/internal/file-report" render={(props) => (
      <FileReport router={props}/>
    )}/>
  </Switch>
)