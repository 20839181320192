import { httpService } from 'libs/react-mpk';
import { hosts } from 'services';

const companyService = {
  get: (params, onAddDataItem) => {
    let host = hosts.getAll().iam.host;
    let { column, keyword, page, size, sortBy, sort } = params;
    let _params = {
      page,
      size,
      field: column,
      column: sortBy,
      order: sort,
      query: keyword,
    };
    
    return httpService.streamGet(
      {
        url: `${host}/api/user_console/company_support`,
        params: _params,
      },
      onAddDataItem
    );
  }
};

export default companyService;
