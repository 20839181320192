import React from 'react';
import { FontIcon } from 'react-md';
import './RatePlanCardImg.scss';

const RatePlanCardImg = ({src, size}) => (
    <div className={`mpk-rate-plan-card-img ${size?('size-'+size):''}`}>
        <div className="default mpk-layout align-center justify-center">
            <FontIcon iconClassName="mdi mdi-cube"/>
        </div>
        {src && <img src={src} role="presentation" alt="rate-plan"/>}
    </div>
)

export default RatePlanCardImg;