import React from 'react';
import { Router } from 'react-router-dom';
import { Notification, Appbar, Sidebar, rdxConnect } from 'libs/react-mpk';
import { utilsService } from 'services';
import routes from './routes';

class Support extends React.Component{
  componentDidMount(){
    utilsService.setReqOptionsCompanyId(null);
    utilsService.setReqOptionsSubscriptionId(null);
    this.props.authActions.setProperties({company: null});
  }

  render(){
    let { global, auth } = this.props;
    return(
      <div className="mpk-layout column fill">
        <Appbar
          notification={<Notification newsFeed={0}/>}
          subname="BKWP Monitoring"
          version={global.appInfo.version}
          profileMenu={[
            {
              label:"Profile",
              materialIcon:'perm_identity',
              onClick:() => {
                window.open(global.appConsole.userAccount.url, 'blank')
              }
            }
          ]}
          consoleShortcut={Object.keys(global.appConsole).map(key => {
            return global.appConsole[key];
          })}
          translate={true}
        />
        <div className="flex mpk-layout">
          <Sidebar
            menus={auth.user.menus}
            getValueByLocaleCode={true}
            collapsible={false}
          />
          <div className="flex mpk-position relative">
            <Router history={this.props.router.history}>
              {routes()}
            </Router>
          </div>
        </div>
      </div>
    )
  }
}

export default rdxConnect(Support);