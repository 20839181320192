import React from 'react';
import { rdxConnect } from 'libs/react-mpk';
import * as Wrapper from '../../libs/react-mpk/components/Wrapper';
import institutionService from './institutionService';
import t from 'counterpart';

class InstitutionForm extends React.Component{
  render(){
    let hasPermission = this.props.auth.hasPermission('PUT:/institution/{id}', 'POST:/institution')
    return(
      <Wrapper.Form
        title={t.translate(`routes.institution.formTitle`)}
        columnValidations={['name', 'userEmail']}
        dialogStyle={{
          width: '100%',
          maxWidth: 480
        }}
        item={this.props.item}
        defaultFormData={() => ({
          name: '',
          // userEmail: ''
        })}
        definitions={[
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('word.name'),
            key: 'name',
            required: true,
            readOnly: !hasPermission
          },
          // {
          //   inputType: Wrapper.Form.types.INPUT,
          //   label: t.translate('word.userEmail'),
          //   key: 'userEmail',
          //   required: true,
          //   type: 'email',
          //   readOnly: !hasPermission
          // }
        ]}
        showSubmit={hasPermission}
        onSubmit={async (data, isEdit, callback) => {
          try {
            let res = isEdit 
              ? await institutionService.put(this.props.item.id, data) 
              : await institutionService.post(data);
            if(isEdit) this.props.tableActions.updateDataItemWithSelector({id: res.data.id}, res.data);
            else this.props.tableActions.addDataItem(res.data);
            callback();
          } catch (error) {
            if(error) callback(true, typeof error.message === 'object' ? error.message[this.props.global.localeCode] : error.message)
          }
        }}
        {...this.props}
      />
    )
  }
} 

export default rdxConnect(InstitutionForm);