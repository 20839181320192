import React from 'react';
import { rdxConnect, navService } from 'libs/react-mpk';
import { Button, Paper } from 'react-md';
import t from 'counterpart';
import { utilsService } from 'services';

class Institution extends React.Component{
  close = () => {
    this.props.tmpObjectActions.setProperties({institution: null})
    utilsService.setReqOptionsInstitutionId(null);
    navService.redirectTo('/internal/institution');
  }
  render(){
    let { auth, tmpObject, global } = this.props;
    let { institution } = tmpObject;
    return global.asInternalService && auth.hasPermission('GET:/institution')? (
      <div>
        { institution ? (
          <div>
            <div className="mpk-margin-S margin-bottom mpk-font-size-S">
              {t.translate('sentence.selectedInstitution')}
            </div>
            <Paper 
              className="mpk-padding-S padding-all"
              style={{
                background: '#f7efe8'
              }}
            >
              <div>
                <div className="mpk-font-size-M mpk-font-weight-B mpk-margin-N margin-bottom">{institution.name}</div>
                {/* <div className="mpk-font-size-NS mpk-margin-S margin-bottom mpk-font-color-D3">{institution.user.email}</div> */}
                <Button
                  raised
                  secondary
                  onClick={this.close}
                >
                  {t.translate('word.close')}
                </Button>
              </div>
            </Paper>
          </div>
        ) : (
          <div className="mpk-font-size-S">
            {`${t.translate('sentence.selectInstitution')} `}
            <span 
              className="mpk-link" 
              onClick={() => navService.redirectTo('/internal/institution')}
            >
              {t.translate('word.here')}
            </span>
          </div>
        )}
      </div>
    ) : null
  }
}

export default rdxConnect(Institution);