import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { FileReport, Registration, Validation} from '../../modules';

export default () => (
  <Switch>
    <Route path="/company/:id/:subscriptionId/registration/:tab" render={(props) => (
      <Registration router={props}/>
    )}/>
    <Route path="/company/:id/:subscriptionId/validation/:tab" render={(props) => (
      <Validation router={props}/>
    )}/>
     <Route path="/company/:id/:subscriptionId/file-report" render={(props) => (
      <FileReport router={props}/>
    )}/>
  </Switch>
)