import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Companies } from '../../modules';

export default () => (
  <Switch>
    <Route path="/main/companies" render={(props) => (
      <Companies router={props}/>
    )}/>
  </Switch>
)