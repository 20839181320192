import React from 'react';
import { Tabs, Tab } from 'react-md';
import rdxConnect from './rdxConnect';
import { LoaderInfo } from '../';
import { merge, find } from 'lodash';

export default (getOptions) => WrappedComponent => {
  class DetailsWrapper extends React.Component{
    constructor(props) {
      super(props);
      this.opt = merge({
        tabs:[
          {
            key: 'general',
            label: 'General',
            disabled: false,
            resourceUri: '',
            component: null
          }
        ],
        onTabChange: (dest) => console.log(dest),
        onInit: (callback) => {callback()},
        fitContainer: true,
        className: ''
      }, getOptions(props));

      this.state = {
        tabs:[],
        components: [],
        tabIdx:0,
        currentTab: 'profile',
        isLoading: true,
      }
    }

    componentWillMount(){
      this.opt.onInit(() => {
        this.setTabs();
      })
    }

    setTabs = () => {
      let tabs = this.opt.tabs.filter( d => (
          this.props.auth.hasPermission(d.resourceUri)) && (d.show ? d.show() : true)
      );

      let components = tabs.map( d => (d.component));

      this.setState({tabs, components, isLoading: false}, () => {
        let _tab = find(tabs, {key:this.props.router.match.params.tab});
        this.setCurrentTab(tabs.indexOf(_tab));
      })
    }

    onTabChange = index => {
      const { tabs } = this.state;
      this.opt.onTabChange(tabs[index])
      this.setCurrentTab(index);
    };

    setCurrentTab = index => {
      let { tabs } = this.state;
      this.setState({
        currentTab: index >=0 ? tabs[index].name : 0,
        tabIdx:index
      });
    };

    render(){
      const { tabIdx, tabs } = this.state;
      
      return(
        <WrappedComponent {...this.props}>
          <div className={`flex mpk-layout column ${this.props.className} ${this.opt.className}`}>
            <div 
              className="flex-none mpk-full width" 
              style={{overflowX: 'auto'}}
            >
                <Tabs
                  tabId="user-detail-tabs flex-none"
                  className="tabs-details"
                  activeTabIndex={tabIdx}
                  mobile
                  onTabChange={this.onTabChange}>
                  {tabs.map((d, i) => (
                    <Tab key={i} label={d.label} disabled={d.disabled}/>
                  ))}
                </Tabs>
            </div>
            <div className={`flex ${this.opt.fitContainer ? 'mpk-content' : ''}`}>
              {this.state.isLoading ? <LoaderInfo/> : this.state.components[tabIdx]}
            </div>
          </div>
        </WrappedComponent>
      )
    }
  }

  DetailsWrapper.defaultProps = {
    classkey: '',
  }

  return rdxConnect(DetailsWrapper);
}
