/**
 * Created by dwiargo on 11/29/17.
 */

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Company, Error, Main, Support, Internal } from './pages';

export default () => (
  <Switch>
    {/* <Route path="/" exact render={(props) => (
      <Redirect to="/main"/>
    )}/> */}
    <Route path="/main" render={(props) => (
      <Main router={props}/>
    )}/>
    <Route path="/company/:companyId/:subscriptionId" render={(props) => (
      <Company router={props}/>
    )}/>
    <Route path="/support" render={(props) => (
      <Support router={props}/>
    )}/>
    <Route path="/internal" render={(props) => (
      <Internal router={props}/>
    )}/>
    <Route path="/error" render={(props) => (
      <Error router={props}/>
    )}/>
  </Switch>
);