import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SearchCompany, Registration, Validation } from '../../modules';

export default () => (
  <Switch>
    <Route path="/support/companies" render={(props) => (
      <SearchCompany router={props}/>
    )}/>
    <Route path="/support/registration/:tab" render={(props) => (
      <Registration router={props}/>
    )}/>
    <Route path="/support/validation/:tab" render={(props) => (
      <Validation router={props}/>
    )}/>
  </Switch>
)