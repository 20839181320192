import React from 'react';
import { Router } from 'react-router-dom';
import { ssoConnect, rdxConnect, navService } from 'libs/react-mpk';
import './App.scss';
import { hosts, utilsService } from 'services';
import SupportMpk from 'libs/support-mpk';
import Script from 'react-load-script';
import routes from './routes';

import createHistory from 'history/createBrowserHistory';

const history = createHistory();

class App extends React.Component{
  componentDidMount(){
    navService.init(history, this.props.globalActions);
  }

  render(){
    let { widgetInterface, kbs, appInfo } = this.props.global;
    return (
      <div className="App mpk-full viewport-height">
        {Object.keys(widgetInterface).map(key => (
          <Script url={widgetInterface[key].url}/>
        ))}
        <Router history={history} className="mpk-position relative">
          {routes(this.props)}
        </Router>
        { kbs && kbs.length > 0 ? (
          <SupportMpk 
            appName={appInfo.name}
            kbs={kbs}
          />
        ) : null}
      </div>
    );
  }
}

export default ssoConnect({
  exchangeToken:'/api/iams/exchangeToken',
  refreshToken:'/api/iams/refreshToken/:refreshToken',
  login:'/api/iams/login',
  me:'/api/iams/me',
}, 
  () => { return hosts.getAll().iam.host }, 
  () => { return hosts.getAll().iam.credentials }, 
  history,
  (user, props) => {    
    const { asInternalService } = props.global;
    let baseUrl = asInternalService ? user.application.baseUrl : user.product.baseUrl;
    utilsService.setReqOptionsBaseUrl(baseUrl)

    let pathNames = window.location.pathname.replace('/','').split('/');
    let rootName = pathNames[0];

    let targetPath;
    for(let i = 0 ; i < user.menus.length ; i++){
      let menu = user.menus[i];
      if(!targetPath && menu.children && menu.children.length > 0){
        targetPath = menu.children[0].path;
        break;
      }
    }

    if(rootName !== 'error'){
      if(asInternalService){
        if(props.tmpObject.asWidget && rootName !== 'widget') navService.redirectTo('/widget');
        else if(rootName !== 'internal') navService.redirectTo(targetPath);
      }else{
        if(user.isSupport){
          if(['support', 'company'].indexOf(rootName) < 0) navService.redirectTo(targetPath);
        } else {
          if(['main', 'company'].indexOf(rootName) < 0 ) navService.redirectTo(targetPath || '/main')
        }
      }
    }
  }, 
  false
)(rdxConnect(App));
