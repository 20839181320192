import React from 'react';
import validationService from './validationService';
import { LoaderInfo, rdxConnect, Hint, NotFoundData } from 'libs/react-mpk';
import moment from 'moment';
import t from 'counterpart';
import Plot from 'react-plotly.js';
import { Paper, Button } from 'react-md';
import { merge } from 'lodash';
import Period from '../../components/Period';

class Chart extends React.Component{
  state = {
    isLoading: true, 
    downloadingReport: false,
    showPeriod: false,
    query: {
      startDate: null, 
      endDate: null,
    },
    resStatusData: {data:[]},
    resLogData:{data:[]}
  }

  componentDidMount(){
    this.fetchData();
  }

  fetchData = async (newQuery={}) => {
    let query = merge(this.state.query, newQuery);
    this.setState({query, isLoading: true});

    try{
      await this.fetchStatusData();
      await this.fetchLogData();
      this.setState({isLoading: false})
    }catch(error){
      this.props.toastActions.izi(
        t.translate('word.failed'),
        typeof error.message === 'object' ? error.message[this.props.global.localeCode] : error.message,
        'warning'
      )   
    }
  }

  fetchStatusData = async () => {
    let { company } = this.props.auth;
    let { asInternalService } = this.props.global;
    let { query } = this.state;

    return new Promise(async (resolve, reject) => {
      try {
        let res = await validationService[asInternalService || company ? 'getByStatus' : 'getSupportByStatus']({
          startDate: moment(query.startDate).toISOString(),
          endDate: moment(query.endDate).toISOString()
        });
        
        let { startDate, endDate, result } = res.data;
        query.startDate = moment(startDate);
        query.endDate = moment(endDate);

        let data = {
          values: [],
          labels: [],
          raw: []
        }
        
        for(let d of result){
          data.labels.push(d.status.replace('Tidak Valid / ', ''))
          data.values.push(Number(d.count))
          data.raw.push(d)
        }

        data.raw = data.raw.sort((a,b) => (a.status > b.status) ? -1 : ((b.status > a.status) ? 1 : 0))

        this.setState({ 
          resStatusData: {data, query}
        }, resolve);
      } catch (error) {
        reject(error);  
      }
    })
  }

  fetchLogData = () => {
    let { company } = this.props.auth;
    let { asInternalService } = this.props.global;
    let { query } = this.state;

    return new Promise(async (resolve, reject) => {
      try {
        let res = await validationService[asInternalService || company ? 'getByLogType' : 'getSupportByLogType']({
          startDate: moment(query.startDate).toISOString(),
          endDate: moment(query.endDate).toISOString()
        });

        let { result } = res.data;

        let data = [];
        for(let key of Object.keys(result)){
          let cdata = {
            label: key,
            values: [],
            labels: [],
            raw: []
          }
        
          for(let d of result[key]){
            cdata.labels.push(d.status.replace('Tidak Valid / ', ''))
            cdata.values.push(Number(d.count))
            cdata.raw.push(d);
          }
          
          cdata.raw = cdata.raw.sort((a,b) => (a.status > b.status) ? -1 : ((b.status > a.status) ? 1 : 0))
          data.push(cdata);
        }

        this.setState({ 
          resLogData: {data}
        }, resolve);
      } catch (error) {
        reject(error)  
      }
    })
  }

  stringDivider = (str, width, spaceReplacer) => {
    if (str.length>width) {
        var p=width
        for (;p>0 && str[p]!=' ';p--) {
        }
        if (p>0) {
            var left = str.substring(0, p);
            var right = str.substring(p+1);
            return left + spaceReplacer + this.stringDivider(right, width, spaceReplacer);
        }
    }
    return str;
  }

  downloadReport = async () => {
    let { startDate, endDate } = this.state.query
    this.setState({downloadingReport: true})
    try {
      let res = await validationService.downloadReport({
        startDate, endDate
      })
      this.props.toastActions.izi(
        t.translate('word.info'),
        res.data,
        'success'
      )
      this.setState({downloadingReport: false})
    } catch (error) {
      this.setState({downloadingReport: false})
      if(error && error.message){
        this.props.toastActions.izi(
          t.translate('word.failed'),
          typeof error.message === 'object' ? error.message[this.props.global.localeCode] : error.message,
          'warning'
        )    
      }
    }
  }

  render(){
    let { resStatusData, resLogData, query } = this.state;
    return(
      <div className={`mpk-full width mpk-layout column ${resStatusData.data.raw && resStatusData.data.raw.length === 0 ? 'height' : ''}`}>
        <Hint className="mpk-position sticky">
          <div className="mpk-layout align-center justify-between flex">
            <span>
              {t.translate('routes.validation.hint.text')}
              {query.startDate && query.endDate ? ` ${moment(query.startDate).format('ll')} - ${moment(query.endDate).format('ll')}` : '...'}
            </span>
            <div className="mpk-layout align-center flex-none">
              <Button
                raised primary
                className="flex-none"
                onClick={() => this.setState({showPeriod: true})}
              >
                {t.translate('sentence.changePeriod')}
              </Button>
              {this.props.auth.hasPermission('GET:/report/export') && (
                <Button 
                  raised
                  className="mpk-margin-N margin-left margin-right flex-none"
                  iconClassName="mdi mdi-download"
                  onClick={this.downloadReport}
                  disabled={this.state.downloadingReport}
                >
                  {t.translate('sentence.downloadReport')}
                </Button>
              )}
            </div>
          </div>
        </Hint>
        { this.state.isLoading ? <LoaderInfo/> : (
          this.state.resStatusData.data.raw.length === 0 ? (
            <div className="mpk-layout align-center justify-center mpk-full width height">
              <NotFoundData
                message={'routes.validation.notFound.message'}
                todo={'routes.validation.notFound.description'}
              />
            </div>
          ) : (
            <div className="mpk-padding-N padding-all mpk-center-container">
              <h2>{t.translate('routes.validation.generalLabel')}</h2>
              <div className="mpk-layout wrap mpk-margin-N margin-bottom">
                {this.state.resStatusData.data.raw.map((d,i) => (
                  <Paper 
                    style={{
                      minWidth: 240
                    }}
                    className={`
                      flex panel-chart mpk-padding-N padding-all mpk-margin-S
                      ${i > 0 ? 'margin-left' : ''}
                      ${i < this.state.resStatusData.data.raw.length - 1  ? 'margin-right' : ''}
                    `}
                  >
                    <div className="mpk-font-color-D3">{d.status}</div>
                    <div className="mpk-font-size-L mpk-margin-S margin-top">{d.count}</div>
                  </Paper>
                ))}
              </div>
              <Paper className="mpk-full width mpk-padding-N padding-all">
                <Plot
                  className="mpk-full width"
                  data={[{
                    ...resStatusData.data,
                    type:'pie'
                  }]}
                  layout={{
                    title: 'Persentase Validasi',
                    autosize: true,
                    height: 480,
                  }}
                />
              </Paper>
              <div className="mpk-margin-N margin-top">
                {resLogData.data.map((d,i) => (
                  <div 
                    className="mpk-margin-N margin-bottom"
                    key={`data-by-log-${i}`}
                  >
                    <h2>{d.label}</h2>
                    <div className="mpk-layout wrap mpk-margin-N margin-bottom">
                      {d.raw.map((r,ri) => (
                        <Paper 
                          key={`${r.status}-${ri}`}
                          style={{
                            minWidth: 240
                          }}
                          className={`
                            flex panel-chart mpk-padding-N padding-all mpk-margin-S
                            ${ri > 0 ? 'margin-left' : ''}
                            ${ri < d.raw.length - 1  ? 'margin-right' : ''}
                          `}
                        >
                          <div className="mpk-font-color-D3">{r.status}</div>
                          <div className="mpk-font-size-L mpk-margin-S margin-top">{r.count}</div>
                        </Paper>
                      ))}
                    </div>
                    <Paper className="mpk-full width mpk-padding-N padding-all">
                      <Plot
                        className="mpk-full width"
                        data={[{
                          ...d,
                          type:'pie'
                        }]}
                        layout={{
                          title: `Persentase ${d.label}`,
                          autosize: true,
                          height: 480,
                          showlegend: false
                        }}
                      />
                    </Paper> 
                  </div>
                ))}
              </div>
            </div>
          )
        )}
        <Period
          visible={this.state.showPeriod}
          onCancel={() => this.setState({showPeriod: false})}
          query={this.state.query}
          onSubmit={ newQuery => this.fetchData(newQuery)}
        />
      </div>
    )
  }
}

export default rdxConnect(Chart);