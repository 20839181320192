import React, { Component } from 'react';
import DialogConfirm from './DialogConfirm';
import DialogAlert from './DialogAlert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as dialogActions from '../../redux/actions/dialogActions';

class Dialog extends Component{
    onHideDialog = (type, actionType) => {
        let __action = this.props.dialog[type === 'alert' ? 'onHide' : 'onCancel']
        if(__action) __action(actionType);
        setTimeout(this.props.dialogActions.hide);
    }

    render(){
        let { title, message, onConfirmed, showConfirmation, showAlert, cancelLabel, submitLabel } = this.props.dialog;
        return(
            <div className="mpk-dialog-root">
                <DialogConfirm
                    visible={showConfirmation}
                    title={title}
                    message={message}
                    onSubmit={(callback) => onConfirmed(callback)}
                    onCancel={this.onHideDialog}
                    submitLabel={submitLabel}
                    cancelLabel={cancelLabel}
                    global={this.props.global}
                    style={{zIndex: 120}}
                />
                <DialogAlert
                    visible={showAlert}
                    title={title}
                    message={message}
                    onCancel={() => this.onHideDialog('alert')}
                    style={{zIndex: 120}}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    dialog: state.dialog,
    global:state.global
})

const mapDispatchToProps = dispatch => ({
    dialogActions:bindActionCreators(dialogActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);