import React, { Component } from 'react';
import Router from 'react-router-dom/Router';
import { Appbar, Sidebar, NotFoundData, rdxConnect, LoaderInfo, navService } from 'libs/react-mpk';
import { utilsService } from 'services';
import t from 'counterpart';
import { find } from 'lodash';

import routes from './routes';
import { Institution } from '../../modules';
import { Selector } from '../../modules/Institution';
import { Button } from 'react-md';

class Internal extends Component{
  render(){
    let { auth, global } = this.props;
    this.props.global.username = auth.user.username;

    return(
      <div className="mpk-layout column fill mpk-full viewport-width viewport-height">
        <Appbar
          subname="BKWP Monitoring"
          showVersion fixedVersion={false}
          profileMenu={[
            {
              label:"Profile",
              materialIcon:'perm_identity',
              onClick:() => {console.log('bisa')}
            }
          ]}
          appsShortcut={auth.user.apps}
          consoleShortcut={Object.keys(this.props.global.appConsole).map(key => {
            return this.props.global.appConsole[key];
          })}
          translate={true}
        />
        <div className="mpk-layout fill mpk-relative-position mpk-content">
          <Sidebar
            menus={auth.user ?  auth.user.menus : []}
            getValueByLocaleCode={true}
            collapsible={false}
            headerElements={
              <div className="mpk-padding-N top left right mpk-border thin solid dark bottom">
                <div className="mpk-font-size-L mpk-margin-S margin-bottom">
                  <div className="mpk-font-size-N">
                    {t.translate('welcome.deck')}
                  </div>
                  <div className="mpk-font-weight-B mpk-font-color-primary">
                    {t.translate('welcome.head')}
                  </div>
                </div>
                { auth.hasPermission('GET:/institution') ? (
                  <Selector/>
                ) : (
                  <div className="mpk-font-size-NS mpk-font-color-D3">
                    {t.translate('welcome.info')}
                  </div>
                )}
              </div>
            }
          />
          <Router history={this.props.router.history}>
            {routes()}
          </Router>
        </div>
      </div>
    )
  }
}

export default rdxConnect(Internal);

